/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React from 'react'
import PropTypes from 'prop-types'

const CIconFa =({name, otherClass, ...props})=>{
    return <i className={name} {...props}/>
}

CIconFa.propTypes = {
    name:PropTypes.string,
    otherClass:PropTypes.string,
    className: PropTypes.string, // Add PropTypes validation for 'blocking' prop
};
export default CIconFa