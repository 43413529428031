import { cilArrowLeft } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import React from "react"

import { useNavigate } from "react-router-dom"

const BackTo=({to, search="", goBack = false, showBackButtonName = true})=>{
    const navigate = useNavigate()

    return <span className="cursor-pointer margin-bottom-10 text-primary" onClick={()=>{
      if(goBack){
        navigate(-1)
      } else{
        navigate({pathname:to, search:search})
      }
    }}><CIcon icon={cilArrowLeft} /> {showBackButtonName?"Kembali":""}</span>
}

export default BackTo