/* eslint-disable eqeqeq */
import _ from "lodash";
import store from "../store";

export async function HttpSend({
  to,
  formData,
  method = "POST",
  url = process.env.REACT_APP_BASE_URL_REST,
  type = "",
  params = {},
  contentType = "application/json"
}) {
  //if(checkToken)
  //isValidToken()

  let form = null;
  let header = {
    "X-BROWSER-ID":localStorage.getItem("browser_id"),
    "X-TIMEZONE":Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  let x_token_type = localStorage.getItem("x_token_type");
  if (x_token_type)
    header = {
      "X-TOKEN-TYPE": localStorage.getItem("x_token_type"),
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "X-INTERNAL-ID": localStorage.getItem("internal_id"),
      "X-BROWSER-ID":localStorage.getItem("browser_id"),
      "X-TIMEZONE":Intl.DateTimeFormat().resolvedOptions().timeZone
    };

  if (method === "POST") {
    if(contentType !== "application/json"){
      form = new FormData();
      if (typeof formData === "object") {
        for (const [key, value] of Object.entries(formData)) {
          form.append(key, value);
        }
      }
    } else {
      form = JSON.stringify(formData);
      header = { ...header, "Content-Type": "application/json" };
    }
  } else if (method === "PUT" || method === "DELETE") {
    form = JSON.stringify(formData);
    console.log("Sini ", form)
    header = { ...header, "Content-Type": "application/json; charset=UTF-8" };
  }
  if (!_.isEmpty(params)) {
    let num = 0;
    _.forOwn(params, function (value, key) {
      if (num === 0) to += "?" + key + "=" + value;
      else to += "&" + key + "=" + value;
      num++;
    });
  }
  // }

  return new Promise((resolve, reject) => {
    fetch(url + "" + to, {
      method: method,
      body: form,
      headers: header,
    })
      .then(function (response) {
        if (type){
          if(type == 'arrayBuffer')
            return response.arrayBuffer()
          return response.blob();
        }
        return response.json();
      })
      .then((res) => {
        resolve(res);
      }).catch(error=>{
        if(to === "users/profilpegawai"){
          reject(error)
        }
        else
          store.dispatch({ type: "set", isLoading: false });
      });
  });
}

/**
 * Generate query for URL
 * 
 * @param string url 
 * @param array params 
 * @returns string
 */
export function UrlQueryBuilder(url, params) {
  for (let i = 0; i < params.length; i++) {
    if (i === 0) {
      url += "?" + params[i].key + "=" + params[i].value;
    } else {
      url += "&" + params[i].key + "=" + params[i].value;
    }
  }

  return url
}

export function QueryGraphQLBuilder(param) {
  let query = "";
  for (let i = 0; i < param.length; i++) {
    if (i === (param.length - 1)) {
      query += param[i].key + ":" + param[i].value;
    } else {
      query += param[i].key + ":" + param[i].value  + ",";
    }
  }

  return query
}

// function isValidToken() {
//   let expires_in = localStorage.getItem("_exp");

//   let now = new Date();
//   //alert((now.getTime() +" "+ (expires_in)))
//   if (now.getTime() > expires_in && store.getState().token) {
//     var request = new XMLHttpRequest();
//     request.open("GET", process.env.REACT_APP_BASE_URL_REST + "refresh", false);
//     request.send();

//     if (request.status === 200) {
//       let result = JSON.parse(request.responseText);
//       if (result.error_code === 0) {
//         store.dispatch({ type: "set", isLoading: false });
//         let now = new Date();
//         now.setSeconds(now.getSeconds() + result.expires_in);
//         localStorage.setItem("_exp", now.getTime());
//       }
//     }
//   }
// }

export function XmlHttpSend({to, formData, method='GET'}){
  var request = new XMLHttpRequest();
    request.open(
      method,
      process.env.REACT_APP_BASE_URL_REST + to,
      false
    );
    
    let x_token_type = localStorage.getItem("x_token_type");
    if (x_token_type) {
      request.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      );
    }
    request.setRequestHeader(
      "X-BROWSER-ID",
      localStorage.getItem("browser_id")
    );
    request.send(formData)

    return request
}