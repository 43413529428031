import { createStore } from 'redux'
let _userinfo = JSON.parse(localStorage.getItem("_userinfo"))
let listPonpes = JSON.parse(localStorage.getItem("listPonpes"))
let listKelas = JSON.parse(localStorage.getItem("listKelas"))

const initialState = {
  sidebarShow: true,
  username: _userinfo ? _userinfo.username : "",
  user_id: _userinfo ? _userinfo.user_id : "",
  id_level: _userinfo ? _userinfo.id_level : "",
  santri:_userinfo?.santri,
  pegawai:_userinfo?.pegawai,
  logo : null,
  listPonpes:listPonpes? listPonpes:[],
  listKelas:listKelas?listKelas:[],
  
  myRole: localStorage.getItem("myRole")
    ? JSON.parse(localStorage.getItem("myRole"))
    : null,
  isLoading: false,
  donationTitle:"",
  isShowExam: false,
  isHideButtomNav: false,
  cbtExamPsb: localStorage.getItem("cbtExamPsb")?JSON.parse(localStorage.getItem("cbtExamPsb")):null,
  cbtExamPsbInfo: localStorage.getItem("cbtExamPsbInfo")?JSON.parse(localStorage.getItem("cbtExamPsbInfo")):null,
  app_short_name:null,
  isLogin: localStorage.getItem("isLogin")?true:false,
  browserId: localStorage.getItem("browserId")?localStorage.getItem("browserId"):false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
