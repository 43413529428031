import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, useParams } from 'react-router-dom'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'moment/locale/id'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale, setDefaultLocale } from "react-datepicker";
import id from 'date-fns/locale/id';
import ResetPassword from './views/pages/password/ResetPassword'
// import OneSignal from 'react-onesignal'
import { v7 as uuidv7 } from 'uuid';

const loading = (
   <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
   </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const RegisterHome = React.lazy(() => import('./views/pages/register/RegisterHome'))
const RegisterForm = React.lazy(() => import('./views/pages/register/RegisterForm'))
const RegisterAccountCommon = React.lazy(() => import('./views/pages/register/RegisterAccountCommon'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ForgetPassword = React.lazy(() => import('./views/pages/password/ForgetPassword'))
const DirectConfirmPayment = React.lazy(() => import('./views/transaction/confirm_payment/DirectConfirmPayment'))
const LoginByGoogle = React.lazy(() => import('./views/pages/login/LoginByGoogle'))
// Donation
const DonationDashboard = React.lazy(() => import('./views/donation/public/DonationDashboard'))
const DonationInCategory = React.lazy(() => import('./views/donation/public/DonationInCategory'))
const DonationDetail = React.lazy(() => import('./views/donation/public/DonationDetail'))
const DonationAmount = React.lazy(() => import('./views/donation/public/DonationAmount'))
const DonationPaymentMethod = React.lazy(()=>import('./views/donation/public/payment/DonationPaymentMethod'))
const DonationPreviewPayment = React.lazy(()=>import('./views/donation/public/payment/DonationPreviewPayment'))
const DonationConfirmPayment = React.lazy(()=>import('./views/donation/public/payment/DonationConfirmPayment'))
const DonationUploadReceipt = React.lazy(()=>import('./views/donation/public/payment/DonationUploadReceipt'))
const DonationHistory = React.lazy(()=>import('./views/donation/public/DonationHistory'))

class App extends Component {
   constructor(props) {
      super(props);
      this.state = { appName: "", appShortName: "" };
   }

   componentDidMount() {
      if(!localStorage.getItem("browserId")){
         localStorage.setItem("browserId", uuidv7())
      }
      if(process.env.REACT_APP_BASE_URL_REST_TRUE === "http://localhost:3030"){
         if(window.location.href.includes("/auth/google/callback") && !window.location.href.includes("/#/auth/google/callback")){
            const baseUrl = window.location.origin
   
            // let updatedUrl = window.location.href.replace(/(http:\/\/localhost:3000)(\/auth\/google\/callback\?)/, '$1/#$2');
            let updatedUrl = window.location.href.replace(`${baseUrl}/auth/google/callback?`, `${baseUrl}/#/auth/google/callback?`);
            console.log("Url ", updatedUrl)
            window.location.href = updatedUrl
         }
      }
      moment.locale('id')
      registerLocale('id', id)
      setDefaultLocale('id')
   }

   render() {
      return (
         <>
            {/* <Helmet>
               <title>{this.state.appShortName + " - " + this.state.appName}</title>
               <meta property="og:title" content={this.state.appShortName + " - " + this.state.appName} />
            </Helmet> */}
            <HashRouter>
               <Suspense fallback={loading}>
                  <Routes>
                     <Route exact path="/login" name="Login Page" element={<Login />} />
                     <Route exact path="/register" name="Register Page" element={<Register />} />
                     <Route exact path="/register_home" name="Register Home" element={<RegisterHome />} />
                     <Route exact path="/register_form" name="Register Home" element={<RegisterForm />} />
                     <Route exact path="/forgetpassword" name="Register Page" element={<ForgetPassword />} />
                     <Route exact path="/request_reset_password/:reset_password_link" name="Reset Password" element={<ResetPassword />} />
                     <Route exact path="/payment/show_direct_confirm/:transaction_id" name='Konfirmasi Pembayaran' element={<DirectConfirmPayment />} />
                     <Route exact path="/auth/google/callback" name='Login by Google' element={<LoginByGoogle />} />
                     <Route exact path="/register_new_account" name="Register Home" element={<RegisterAccountCommon />} />

                  
                     <Route exact path="/donation" name='Taawun & Donasi' element={<DonationDashboard />} />
                     <Route exact path="/donation/in_category/:donation_category_id" name='Taawun & Donasi' element={<DonationInCategory />} />
                     <Route exact path="/donation/history" name='Taawun & Donasi' element={<DonationHistory />} />
                     <Route exact path="/donation/detail/:donation_post_id" name='Taawun & Donasi' element={<DonationDetail />} />
                     <Route exact path="/donation/select_amount/:donation_post_id" name='Taawun & Donasi' element={<DonationAmount />} />
                     <Route exact path="/donation/select_payment_method/:donation_post_id" name='Methode Pembayaran' element={<DonationPaymentMethod />} />
                     <Route exact path="/donation/preview_payment/:donation_post_id" name='Preview Pembayaran' element={<DonationPreviewPayment />} />
                     <Route exact path="/donation/confirm_payment/:transaction_id" name='Konfirmasi Pembayaran' element={<DonationConfirmPayment />} />
                     <Route exact path="/donation/upload_receipt/:transaction_id" name='Konfirmasi Pembayaran' element={<DonationUploadReceipt />} />
                     
                     <Route exact path="/404" name="Page 404" element={<Page404 />} />
                     <Route exact path="/500" name="Page 500" element={<Page500 />} />
                     <Route path="*" name="Home" element={<DefaultLayout />} />
                  </Routes>
               </Suspense>
            </HashRouter>
         </>
      )
   }
}

export default App
