/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React from 'react'
import { CBadge, CModal, CModalBody, CModalHeader } from "@coreui/react"
import moment from "moment"
import Swal from "sweetalert2"
import { HttpSend, XmlHttpSend } from "./Network"
import _ from "lodash"
import CIcon from "@coreui/icons-react"
import { toast } from "react-toastify"
import CIconFa from "../components/CIconFa"
import { API_V1 } from './api'
import arabic from "react-date-object/calendars/arabic"
import arabic_en from "react-date-object/locales/arabic_en"
import DateObject from "react-date-object";
import gregorian from "react-date-object/calendars/gregorian"
import gregorian_en from "react-date-object/locales/gregorian_en"
import momentHijri from 'moment-hijri'

export function sumPagination(total, rowPerPage) {
	// return total > rowPerPage
	//   ? Math.ceil(
	//       (parseInt(total) + parseInt(rowPerPage) - 1) / parseInt(rowPerPage)
	//     )
	//   : 1

	return total > rowPerPage
		? Math.ceil(parseInt(total) / parseInt(rowPerPage))
		: 1
}

// export function aesEncrypt(data) {
//   let encrypted = aes
//     .encrypt(data, key, { iv: iv, padding: padZeroPadding })
//     .toString()
//   encrypted = JSON.stringify(encrypted)
//   encrypted = CryptoJS.enc.Utf8.parse(encrypted)
//   return CryptoJS.enc.Base64.stringify(encrypted)
// }

// export function aesDecrypt(encrypted) {
//   try {
//     encrypted = CryptoJS.enc.Base64.parse(encrypted)
//     encrypted = encrypted.toString(CryptoJS.enc.Utf8)
//     var decrypt = CryptoJS.AES.decrypt(JSON.parse(encrypted), key, {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: padZeroPadding,
//     })
//     return decrypt.toString(CryptoJS.enc.Utf8)
//   } catch (err) {
//     return encrypted
//   }
// }

export function status(status) {
	if (status === 1) return <CBadge color="success">Ya</CBadge>
	return <CBadge color="danger">Tidak</CBadge>
}

export function status_verifikasi(status) {
	if (status === "V") return <CBadge color="success">Valid</CBadge>
	else if (status === "B")
		return <CBadge color="info">Belum Diverifikasi</CBadge>
	return <CBadge color="danger">Tidak Valid</CBadge>
}

export function status_aktif(status) {
	if (status === 1) return <CBadge color="success">Aktif</CBadge>
	return <CBadge color="danger">Tidak Aktif</CBadge>
}

export function status_aktif_new(status) {
	if (status || status === 1) {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-check-circle"
					size="sm"
					style={{ color: "#50D695", marginRight: 5 }}
				/>
				Aktif
			</span>
		)
	}

	return <span style={{ fontStyle: "italic" }}>Tidak Aktif</span>
}

export function status_aktif_skillset(status) {
	if (status || status === true) {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-check-circle"
					size="lg"
					style={{ color: "#50D695", marginRight: 5 }}
				/>
			</span>
		)
	}

	return <span style={{ fontStyle: "italic" }}>
		<CIcon
			name="cil-check-circle"
			size="lg"
			style={{ color: "#D6D9DA", marginRight: 5 }}
		/>
	</span>
}

export function sumber_data_camaba(sumber) {
	if (sumber === 1)
		return <CBadge color="warning">Pendaftaran Langsung</CBadge>
	return <CBadge color="info">Import Data</CBadge>
}

export const customStylesSelect = {
	container: (provided) => ({
		...provided,
		width: 100,
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			fontSize: "12px",
			textAlign: "left",
			width: "auto",
		}
	},
	menu: (base) => ({
		...base,
		width: "max-content",
		minWidth: "100%",
		zIndex: 9999
	}),
}

export function upperCaseWord(word, onlyFirst = true) {
	if (typeof word !== "undefined") {
		word = word.toString().toLowerCase()
		return word.charAt(0).toUpperCase() + word.substring(1)
	}
	return null
}

export function normalDate(tgl, calendarType = 'Masehi') {
	if (calendarType === 'Hijriyah')
		return toHijri(tgl)

	return moment(tgl).format("YYYY-MM-DD")
}

export function normalDateSais(tgl, calendarType = 'Masehi') {
	if (calendarType === 'Hijriyah')
		return toHijri(tgl)

	return toGregorian(tgl)
}

export function indoDate(tgl) {
	return moment(tgl).format("DD/MM/YYYY")
}

export function indoDateLong(tgl) {
	if (tgl)
		return moment(tgl).format("DD/MM/YYYY HH:mm")
	return ""
}

export function tgl_long_default(tgl) {
	return moment(tgl).format("YYYY-MM-DD HH:mm:ss")
}

export function dobFormat(tgl) {
	if (tgl)
		return moment(tgl).format("DD MMMM YYYY")
	return ""
}

function MyDate(tgl) {
	var dates = moment
	dates.updateLocale("en", {
		weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"],
		months: [
			"Januari",
			"Februari",
			"Maret",
			"April",
			"Mei",
			"Juni",
			"Juli",
			"Agustus",
			"September",
			"Oktober",
			"November",
			"Desember",
		],
	})
	return dates(tgl)
}

export function tgl_long(tgl) {
	if (tgl) return MyDate(tgl).format("DD MMMM YYYY - H:mm:ss")
	return "-"
}

export function tglIndoWithTime(tgl) {
	if (tgl) return MyDate(tgl).format("DD MMMM YYYY - HH:mm")
	return "-"
}

export function hour(jam) {
	if (jam) return moment(jam, "HH:mm").format("HH:mm")
	return "-"
}


export function hourByDate(date) {
	if (date) return MyDate(date).format("HH:mm")
	return "-"
}

export function today() {
	return moment().format("YYYY-MM-DD")
}

export function formatUang(amount, decimal = 0) {
	if (amount){
		let result = amount.toLocaleString("in-ID", {
			// style: "currency",
			// currency: "IDR",
			maximumFractionDigits: decimal,
		})

		if(result){
			return `Rp${result}`
		}
	}
	return "-"
}

export function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export function getBinary(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsBinaryString(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export function blobToBase64(blob, callback) {
	const reader = new FileReader();
	reader.onload = function () {
		const base64Data = reader.result.split(',')[1];
		callback(base64Data);
	};
	reader.readAsDataURL(blob);
}

export const correctParams = (parameterName, message, isSelect) => {
	if (isSelect) {
		if (parameterName.value === undefined || parameterName.value === "") {
			Swal.fire(
				"Terjadi Kesalahan",
				"Harap pilih " + message + " terlebih dahulu",
				"error"
			)
			return false
		}
	} else {
		if (parameterName === "") {
			Swal.fire(
				"Terjadi Kesalahan",
				"Harap pilih " + message + " terlebih dahulu",
				"error"
			)
			return false
		}
	}

	return true
}

export const listJenjangPendidikan = (params) => {
	return HttpSend({
		method: "GET",
		to: "settings/jenjang_pendidikan/list/-1/1",
		params: params,
	})
}

export const getProdi = (id_prodi) => {
	return HttpSend({
		method: "GET",
		to: "settings/prodi/detail/" + id_prodi,
	})
}

export const listProdi = (params) => {
	return HttpSend({
		method: "GET",
		to: "settings/prodi/list/-1/1",
		params: params,
	})
}

export const listFakultas = (params) => {
	return HttpSend({
		method: "GET",
		to: "settings/fakultas/list/-1/1",
		params: params,
	})
}

export const getFakultas = (id_fakultas) => {
	return HttpSend({
		method: "GET",
		to: "settings/fakultas/detail/" + id_fakultas,
	})
}

export const getAllTahun = (params) => {
	return HttpSend({
		method: "GET",
		to: "settings/tahun_ajaran/list/-1/1",
		params: params,
	})
}

export const getTahun = (id_tahun_ajaran) => {
	return HttpSend({
		method: "GET",
		to: "settings/fakultas/detail/" + id_tahun_ajaran,
	})
}

export const getAllPeriode = (params) => {
	return HttpSend({
		method: "GET",
		to: "settings/semester/list/-1/1",
		params: params,
	})
}

export const getPeriode = (id_semester) => {
	return HttpSend({
		method: "GET",
		to: "settings/semester/detail/" + id_semester,
	})
}

export const getSemester = (ponpesId, jenis = 'akademik') => {
	return HttpSend({
		method: "GET",
		to: API_V1 + "/semester/semester_active",
		params: {
			ponpes_id: ponpesId,
			jenis: jenis
		},
	})
}

export const iSMyRole = (idRole, listRole) => {
	if (listRole != null) {
		let role = _.find(listRole, { id_role: idRole })
		if (role != null) return true
	}
	return false
}

export const weekdays = [
	"Senin",
	"Selasa",
	"Rabu",
	"Kamis",
	"Jumat",
	"Sabtu",
	"Ahad",
]

export const dayOfWeekIndex = (hari) => {
	return _.indexOf(weekdays, hari)
}

export const getSemesterAktif = (ponpesId, jenis = "akademik") => {
	return HttpSend({
		method: "GET",
		to: API_V1 + "/semester/semester_active",
		params: {
			ponpes_id: ponpesId,
			jenis: jenis
		},
	})
}

export const getDefaultKurikulum = (id_prodi) => {
	return HttpSend({
		method: "GET",
		to: "kurikulum/kurikulum_default/" + id_prodi,
	})
}

export const getDefaultKurikulumByFakultas = (id_fakultas) => {
	return HttpSend({
		method: "GET",
		to: "kurikulum/kurikulum_default_byfakultas/" + id_fakultas,
	})
}

export const getJenisRuangan = [
	{ value: "gedung", label: "Gedung" },
	{ value: "laboratorium", label: "Laboratorium" },
	{ value: "aula", label: "Aula" },
	{ value: "ruang_kuliah", label: "Ruang Kuliah" },
	{ value: "lapangan", label: "Lapangan" },
	{ value: "ruang_ujian", label: "Ruang Ujian" },
	{ value: "kantor", label: "Kantor" },
	{ value: "lainnya", label: "Lainnya" },
]

export const setLocaleStorage = (key, value, expired_at) => {
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + expired_at,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

export const getLocaleStorage = (key) => {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

export const semester = [1, 2, 3, 4, 5, 6, 7, 8]

export const showToast = (message, type = "success") => {
	switch (type) {
		case "success":
			toast.success(message, {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 2000,
			})
			break
		case "error":
			toast.error(message, {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 2000,
			})
			break
		case "warning":
			toast.warning(message, {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 2000,
			})
			break
		case "info":
			toast.info(message, {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 2000,
			})
			break
		default:
	}
}

export const getDosenById = (id_dosen) => {
	return HttpSend({
		method: "GET",
		to: "dosen/detail/" + id_dosen,
	})
}

export const getQueryParam = (key) => {
	const hash = window.location.hash.slice(1); // Remove the '#' from the hash
	const queryString = hash.split('?')[1];
	return new URLSearchParams(queryString).get(key)
}

export const showSizeFile = (fileSizeBytes) => {
	let result = _.round(fileSizeBytes / (1024 * 1024), 1)
	if (result >= 1) return result + " MB"
	else {
		result = _.round(fileSizeBytes / 1024, 1)
		return result + " KB"
	}
}

export const showNameWithTitle = (
	gelar_depan,
	nama_depan,
	nama_belakang,
	gelar_belakang
) => {
	let result = ""
	if (!_.isEmpty(gelar_depan)) result += gelar_depan + ". "
	result += nama_depan
	if (!_.isEmpty(nama_belakang)) {
		if (nama_belakang.toUpperCase() != "NULL")
			result += " " + nama_belakang
	}
	if (!_.isEmpty(gelar_belakang)) result += ", " + gelar_belakang

	return result
}

export const showFirstLastName = (nama_depan, nama_belakang) => {
	let result = nama_depan
	if (!_.isEmpty(nama_belakang)) result += " " + nama_belakang
	return result
}

export function tglIndoNormal(tgl, withDay = false, calendarType = "") {
	if (calendarType === TAHUN_HIJRIYAH) {
		if (withDay) {
			return momentHijri(tgl).format("dddd, iDD iMMMM iYYYY")
		}
		return momentHijri(tgl).format("iDD iMMMM iYYYY")

	}
	else {
		if (tgl) {
			if (withDay) {
				return moment(tgl).format("dddd, DD MMMM YYYY")
			}
			return moment(tgl).format("DD MMMM YYYY")
		}
	}
	return ""
}

export const generateLinkWithIcon = (link) => {
	if (_.isEmpty(link)) return

	if (link.includes("zoom")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_conference/zoom.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link Zoom</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	} else if (link.includes("drive.google")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_drive/gdrive.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link Google Drive</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	} else if (link.includes("onedrive")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_drive/microsoft-onedrive.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link One Drive</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	} else if (link.includes("meet.google")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_conference/gmeet.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link Google Meet</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	} else if (link.includes("teams.microsoft")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_conference/microsoft-teams.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link Microsoft Teams</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	} else if (link.includes("dropbox")) {
		return (
			<table>
				<tr style={{ width: "100px", height: "70px" }}>
					<td className="td-link-box">
						<img
							src={"../../icon_drive/dropbox.png"}
							width={40}
							className="avatar"
							style={{ marginRight: 10 }}
							alt=""
						/>
					</td>
					<td className="td-link-box" style={{ maxWidth: 150 }}>
						<div style={{ display: "block" }}>
							<div className="text-12 text-bold">Link Dropbox</div>
							<div className="text-10">
								<a href={link} target="_blank" rel="noreferrer">
									{link}
								</a>
							</div>
						</div>
					</td>
				</tr>
			</table>
		)
	}
}

export const statusJurnalKelas = (statusPost) => {
	switch (statusPost) {
		case 1:
			return ""
		case 2:
			return <span className="text-secondary text-12">Dijadwalkan</span>
		case 3:
			return <span className="text-secondary text-12">Draft</span>
	}
}

export const onErrorLoadImage = (e, alternativeImage) => {
	e.target.src = alternativeImage
}

export const getCurrentBobot = () => {
	return HttpSend({
		method: "GET",
		to: "settings/bobot_nilai_huruf/current_bobot",
	})
}

export const roundValue = (num, decimal) => {
	if (num) return Number(Math.round(num + "e" + decimal) + "e-" + decimal)
	return ""
}


export const roundDownValue = (num, decimal) => {
	if (num) return Number(Math.floor(num + "e" + decimal) + "e-" + decimal)
	return ""
}


export const valueInput = (e) => {
	const target = e.target
	let value = null

	switch (target.type) {
		case "checkbox":
			value = target.checked
			break
		case "file":
			value = target.files[0]
			break
		default:
			value = target.value
			break
	}

	return value
}

export const jamAkhir = (jamAwal, durasi) => {
	return moment(jamAwal, "HH:mm").add(durasi, "minutes").format("HH:mm")
}

// Default golongan ukt mbkm
export const defaultUktMbkm = () => {
	return {
		id_golongan_ukt: 5,
		nama_golongan: "Golongan 5",
	}
}

export const statusTagihan = (status) => {
	switch (status) {
		case "Lunas":
			return <span style={{ color: "#50D695" }}>Lunas</span>
		case "Belum Bayar":
			return <span style={{ color: "#F86C6B" }}>Belum Bayar</span>
		default:
			if (status.includes("Parsial")) {
				return <span style={{ color: "#F29715" }}>{status}</span>
			}
			break
	}
}

export const getAktivitasMahasiswaBimbinganUjian = (id_aktivitas_mahasiswa) => {
	return HttpSend({
		method: "GET",
		to:
			"aktivitas_mahasiswa/bimbingan_ujian/detail_by_aktivitas/" +
			id_aktivitas_mahasiswa,
	})
}

export const getBimbinganUjianDosenPembimbing = (id_aktivitas_mahasiswa) => {
	return HttpSend({
		method: "GET",
		to:
			"aktivitas_mahasiswa/bimbingan_ujian/detail_by_aktivitas/" +
			id_aktivitas_mahasiswa,
		params: {
			is_pembimbing: true,
		},
	})
}

export const getBimbinganUjianDosenPenguji = (id_aktivitas_mahasiswa) => {
	return HttpSend({
		method: "GET",
		to:
			"aktivitas_mahasiswa/bimbingan_ujian/detail_by_aktivitas/" +
			id_aktivitas_mahasiswa,
		params: {
			is_pembimbing: false,
		},
	})
}

export const getAnggotaAktivitasMahasiswa = (id_aktivitas_mahasiswa) => {
	return HttpSend({
		method: "GET",
		to: "aktivitas_mahasiswa/get_anggota_aktivitas/" + id_aktivitas_mahasiswa,
	})
}

export const getKategoriKegiatan = () => {
	return HttpSend({
		method: "GET",
		to: "settings/kategori_kegiatan/list/-1/1",
		params: {
			keywords: "",
		},
	})
}

export const isShowAktivitas = (mahasiswa_bimbingan_ujian, id_dosen) => {
	for (const el of mahasiswa_bimbingan_ujian) {
		return el.userid_dosen == id_dosen
	}
	return false
}

export const getStatusLirsAndMbkmMahasiswa = () => {
	return HttpSend({
		to: "mahasiswa/get_status_lirs_and_mbkm",
		method: "GET",
	})
}

export const getSksMax = (nim) => {
	return HttpSend({
		to: "mahasiswa/sks_max/" + nim,
		method: "GET",
	})
}

export const StaticFileUrl = () => {
	return (
		process.env.REACT_APP_BASE_URL + "" + process.env.REACT_APP_BASE_URL_REST
	)
}

export const statusPembayaranEtp = (value) => {
	if (value === 0) return "Belum Lunas"
	else if (value === 1) return "Lunas"
	else return "Gratis"
}

export const PreviewPdf = ({ streamData, showFile, onClosed }) => {
	return (
		<CModal
			show={showFile}
			onClosed={onClosed}
			size="xl"
			closeOnBackdrop={false}
		>
			<CModalHeader closeButton></CModalHeader>
			<CModalBody>
				<iframe
					id="framePdf"
					name="preview"
					type="application/pdf"
					width="100%"
					height={window.innerHeight - 150}
					style={{ border: "none" }}
					src={streamData}
				>
					Your browser is does'nt support to show pdf
				</iframe>
			</CModalBody>
		</CModal>
	)
}

export function status_validasi_lirs(status) {
	if (status === null) {
		return (
			<span style={{ fontStyle: "italic" }} className="text-grey">
				Belum Direview
			</span>
		)
	} else if (status) {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-check-circle"
					size="sm"
					style={{ color: "#50D695", marginRight: 5 }}
				/>
			</span>
		)
	} else {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-x"
					size="sm"
					style={{ color: "#F86C6B", marginRight: 5 }}
				/>
			</span>
		)
	}
}

export function status_konversi_lirs(status) {
	if (status === null) {
		return (
			<span style={{ fontStyle: "italic" }} className="text-grey">
				Belum Dikonversi
			</span>
		)
	} else if (status) {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-check-circle"
					size="sm"
					style={{ color: "#50D695", marginRight: 5 }}
				/>
			</span>
		)
	} else {
		return (
			<span style={{ fontStyle: "italic" }}>
				<CIcon
					name="cil-x"
					size="sm"
					style={{ color: "#F86C6B", marginRight: 5 }}
				/>
			</span>
		)
	}
}

export const showIconApproval = (isApproved) => {
	let iconName = ""
	if (isApproved === null) {
		iconName = "fa-sharp fa-solid fa-circle-check"
	} else if (isApproved) {
		iconName = "fa-sharp fa-solid fa-circle-check text-success"
	} else {
		iconName = "fa-solid fa-circle-xmark text-danger"
	}
	return <CIconFa name={iconName} />
}

export function statusPerkuliahan(status, label) {
	switch (status) {
		case "A":
			return <CBadge color="success">{label}</CBadge>
		case "C":
			return <CBadge color="warning">{label}</CBadge>
		case "G":
			return <CBadge color="secondary">{label}</CBadge>
		case "M":
			return <CBadge color="info">{label}</CBadge>
		case "N":
			return <CBadge color="danger">{label}</CBadge>
		case "Z":
			return <CBadge color="dark">{label}</CBadge>
	}
}

export const errorValidationMessage = (field, errorList) => {
	const index = errorList.findIndex(item => item.context.label === field)
	return errorList[index] ? errorList[index].message : null
}

export const errorValidationMessageList = (field, errorList, idx) => {
	const index = errorList.findIndex(item => item.context.key === field && item.message.includes(`[${idx}]`))
	return errorList[index] ? errorList[index].message.replace(`[${idx}]`, '') : null
}

export const getTahunPendaftaranActive = (ponpesId, isOpenPendaftaran = null) => {
	let params = {
		ponpes_id: ponpesId
	}

	if (isOpenPendaftaran !== null)
		Object.assign(params, { is_open_pendaftaran: isOpenPendaftaran })
	return HttpSend({
		to: API_V1 + "/tahun/get_tahun_pendaftaran_active",
		method: 'GET',
		params: params
	})
}

export const getTahunAkademikActive = (ponpesId) => {
	let params = {
		ponpes_id: ponpesId,
		jenis: "akademik"
	}

	return HttpSend({
		to: API_V1 + "/tahun/get_tahun_akademik_active",
		method: 'GET',
		params: params
	})
}


export function statusAktif(status) {
	if (status)
		return <CBadge color="success">Aktif</CBadge>
	return <CBadge color="danger">Tidak Aktif</CBadge>
}

export function yesOrNo(status) {
	if (status)
		return <CBadge color="success">Ya</CBadge>
	return <CBadge color="danger">Tidak</CBadge>
}

export function getFileTypeFromFileName(fileName) {
	const extension = fileName.split('.').pop().toLowerCase();

	if (extension === 'pdf') {
		return 'pdf';
	} else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
		return 'image';
	} else {
		return 'unknown';
	}
}

export function isMobile() {
	var r = new RegExp("Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini");
	return r.test(navigator.userAgent);
}

export function gender(sex) {
	if (sex === 'L')
		return <CBadge color="success">Banin/Laki - Laki</CBadge>
	else if (sex === 'P')
		return <CBadge color="primary">Banat/Perempuan</CBadge>

	return sex
}

export function genderGeneral(sex) {
	if (sex === 'L')
		return <CBadge color="success">Laki - Laki</CBadge>
	else if (sex === 'P')
		return <CBadge color="primary">Perempuan</CBadge>

	return sex
}

export function statusPendaftaran(status) {
	if (status === 'L')
		return <CBadge color="success">Lulus</CBadge>
	else if (status === 'TL')
		return <CBadge color="danger">Tidak Lulus</CBadge>

	return <CBadge color="secondary">Belum diverifikasi</CBadge>
}

export function statusBerkas(status) {
	if (status === 'L')
		return <CBadge color="success">Lengkap</CBadge>
	else if (status === 'TL')
		return <CBadge color="danger">Ditolak</CBadge>
	else if (status === 'RV')
		return <CBadge color="warning">Direvisi</CBadge>

	return <CBadge color="secondary">Belum diverifikasi</CBadge>
}

export function downloadFile(fileName, data, type = "text/csv") {
	const blob = new Blob([data], { type: type });
	const url = URL.createObjectURL(blob);

	const anchor = document.createElement('a');
	anchor.href = url;
	anchor.download = fileName;
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

export function downloadFileUrl(url, fileName) {
	const anchor = document.createElement('a');
	anchor.href = url;
	anchor.download = fileName;
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

export const changeFavicon = (url) => {
	const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = 'shortcut icon';
	link.href = url;
	document.getElementsByTagName('head')[0].appendChild(link);
}

export const getKurikulumActive = (ponpesId, jenjangPendidikanId) => {
	let params = {
		ponpes_id: ponpesId,
		jenjang_pendidikan_id: jenjangPendidikanId
	}

	if (jenjangPendidikanId === null || jenjangPendidikanId === undefined)
		return null

	return HttpSend({
		to: API_V1 + "/kurikulum_pelajaran/kurikulum/kurikulum_active",
		method: 'GET',
		params: params
	})
}

export const getAllSantriByRoom = (kelasRuangId) => {
	return HttpSend({
		to: API_V1 + "/santri/get_santri_by_room",
		method: 'GET',
		params: {
			kelas_ruang_id: kelasRuangId
		}
	})
}

export function jenisMapel(jenis) {
	let color = ""
	if (jenis === 'Wajib')
		color = 'info'
	else if (jenis === 'Ekstrakurikuler')
		color = 'warning'
	else
		color = 'dark'

	return <CBadge color={color}>{jenis}</CBadge>
}

export function statusKelulusan(status) {
	if (status)
		return <CBadge color="success">Lulus</CBadge>
	else
		return <CBadge color="danger">Tidak Lulus</CBadge>
}

export function statusKetidakhadiran(status) {
	if (status === 'Sakit')
		return <CBadge color="success">{status}</CBadge>
	else if (status === 'Izin')
		return <CBadge color="info">{status}</CBadge>

	return <CBadge color="danger">{status}</CBadge>
}

export function defaultJenjangPendidikan() {
	const kelas = JSON.parse(localStorage.getItem("listKelas"))
	let selected = [];
	if (kelas !== null) {
		kelas.forEach((el) => {
			let jenjang = el.jenjang_pendidikan
			let rangeUsia = ""
			if (jenjang.min_age_range) {
				rangeUsia = " - " + jenjang.reception_type + " (" + jenjang.min_age_range + " - " + jenjang.max_age_range + ") Tahun"
			}

			selected.push({
				value: jenjang.jenjang_pendidikan_id,
				label: jenjang.nama_jenjang_pendidikan + rangeUsia,
				detail: jenjang
			});

		})

		selected = _.uniqBy(selected, function (e) {
			return e.value
		})
	}
	if (selected.length > 0)
		return selected[0]
	return null
}


export function statusSantri(status) {
	if (status === STATUS_AKTIF)
		return <CBadge color="success">Aktif</CBadge>
	else if (status === STATUS_TIDAK_AKTIF)
		return <CBadge color="danger">Tidak Aktif</CBadge>
	else if (status === STATUS_LULUS)
		return <CBadge color="primary">Lulus</CBadge>
	else if (status === STATUS_KELUAR)
		return <CBadge color="danger">Keluar</CBadge>

	return "-"
}

export function getPonpesByWebsite(isReturn = false) {
	const baseUrl = window.location.origin
	if (!isReturn) {
		const request = XmlHttpSend({
			to: API_V1 + "/ponpes/get_ponpes_by_website?custom_domain=" + baseUrl,
			method: 'GET',
		})

		if (request.status === 200) {
			let data = JSON.parse(request.responseText).data;
			if (data) {
				changeFavicon(process.env.REACT_APP_FILE_DOMAIN + "/" + data?.logo)
				localStorage.setItem('baseUrl', baseUrl)
				localStorage.setItem('private_domain', true)
				localStorage.setItem('ponpes_data_domain', JSON.stringify(data))
				if (data?.custom_app_name) {
					document.title = data?.custom_app_short_name + " - " + data?.custom_app_name
				}
			} else {
				localStorage.setItem('baseUrl', process.env.REACT_APP_BASE_URL)
				localStorage.setItem('private_domain', false)
			}
		}
	} else {
		return HttpSend({
			to: API_V1 + "/ponpes/get_ponpes_by_website?custom_domain=" + baseUrl,
			method: 'GET'
		})
	}
}

export function getBaseUrl() {
	let baseUrl = localStorage.getItem('baseUrl')
	if (baseUrl) {
		return baseUrl
	} else {
		console.log("From default base url")
		return process.env.REACT_APP_BASE_URL
	}
}

export function getLocalStorage(key) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}

export function removeWhenLogout() {
	localStorage.removeItem('isLogin');
	localStorage.removeItem('access_token');
	localStorage.removeItem('x_token_type');
	localStorage.removeItem('_userinfo')
	// localStorage.clear()
}

export const toHijri = (date) => {
	return new DateObject(date).convert(arabic, arabic_en).format()
}

export const toGregorian = (date, format = "YYYY-MM-DD") => {
	if (date instanceof DateObject)
		return new DateObject(date).convert(gregorian, gregorian_en).format(format)

	return new moment(date, "DD/MM/YYYY HH:mm").format(format)
}

export const getHijriDate = (dateIn, format = "YYYY/MM/DD") => {
	const date = new DateObject({
		date: dateIn,
		format: format,
		calendar: arabic,
		locale: arabic_en
	})

	return date
}

export const arabicMonth = [
	'Muharram', 'Safar', 'Rabi al-Awwal', 'Rabi al-Thani', 'Jumada al-Ula', 'Jumada al-Akhirah',
	'Rajab', 'Sha\'ban', 'Ramadhan', 'Shawwal', 'Dhu al-Qi\'dah', 'Dhu al-Hijjah'
]

export const arabicShortMonth = [
	'Muh', 'Saf', 'R.Awwal', 'R.Thani', 'J.Ula', 'J.Akhirah',
	'Raj', 'Sha', 'Ram', 'Shaw', 'DhuQ', 'DhuH'
]

export const masehiMonth = [
	'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
	'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
]

export const masehiShortMonth = [
	'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
	'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'
]

export const renderDate = (date, calendarType = 'Masehi', format = "DD/MM/YYYY") => {
	if (date === null)
		return ""
	// if(calendarType === 'Hijriyah')
	// 	return new DateObject(date).convert(arabic, arabic_en).format(format)
	// return new DateObject(date).convert(gregorian, gregorian_en).format(format)
	if (calendarType === 'Hijriyah') {
		const gregorianDate = momentHijri(date, 'YYYY/MM/DD');
		// const hijriDate = gregorianDate.format('iD/iM/iYYYY');
		gregorianDate.local = "id"
		if (format === "DD/MM/YYYY")
			format = "iD/iMM/iYYYY"
		else if (format === "DD MMMM YYYY") {
			format = "iD iM iYYYY"
			let hijri = gregorianDate.format(format)
			let split = hijri.split(" ")

			return split[0] + " " + arabicMonth[parseInt(split[1]) - 1] + " " + split[2]
		}
		return gregorianDate.format(format)
	}
	// return new DateObject(date).convert(gregorian, gregorian_en).format(format)
	return moment(date).format(format)
}

export const cleanRupiahToNumber = (value = "", thousandSeparator = ".") => {
	let result = value.replace("Rp", "").replace(".", "").replace(",", ".")
	if (result)
		return parseFloat(result)
	return value
}

export function statusInvoice(status) {
	switch (status) {
		case STATUS_INV_PAID:
			return <CBadge color="success">Lunas</CBadge>
		case STATUS_INV_UNPAID:
			return <CBadge color="warning">Belum Dibayar</CBadge>
		case STATUS_INV_PAID_PARTIAL:
			return <CBadge color="info">Dibayar Parsial</CBadge>
		case STATUS_INV_NOT_ACTIVE:
			return <CBadge color="danger">Tidak Aktif</CBadge>
		case STATUS_INV_VOID:
			return <CBadge color="secondary">Dikosongkan</CBadge>
		default:
			return ""
	}
}

export const MetodePembayaran = (paymentMethodType, isInstitution = false) => {
	let append = ""
	if(isInstitution)
		append = "Lembaga"
	switch (paymentMethodType) {
		case "manual":
			return `Manual ${append}`
		case "va":
			return `Virtual Account ${append}`
		case "ewallet":
			return `Ewallet ${append}`
		case "qr":
			return `QR ${append}`
		case "others":
			return `Lainnya ${append}`
	}
}

export const copyText = (text, withToast = true) => {
	navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
		if (result.state === "granted" || result.state === "prompt") {
			navigator.clipboard.writeText(text).then(function () {
				if (withToast)
					showToast('Text disalin!', 'info')
				else
					alert('Text disalin!')
			}, function (err) {
				console.error('Async: Could not copy text: ', err);
			});
		} else { }
	});
}

export function statusManualTransfer(status) {
	switch (status) {
		case PAYMENT_STATUS_MANUAL_SUCCESS:
			return <CBadge color="success">Sukses</CBadge>
		case PAYMENT_STATUS_MANUAL_ON_PROGRESS:
			return <CBadge color="info">Sedang Diproses</CBadge>
		case PAYMENT_STATUS_MANUAL_PENDING:
			return <CBadge color="warning">Menunggu Pembayaran</CBadge>
		case PAYMENT_STATUS_MANUAL_REJECTED:
			return <CBadge color="danger">Ditolak</CBadge>
		default:
			return ""
	}
}

export function statusPayment(status) {
	switch (status) {
		case PAYMENT_STATUS_SUCCESS:
			return <CBadge color="success">Sukses</CBadge>
		case PAYMENT_STATUS_PENDING:
			return <CBadge color="info">Menunggu Pembayaran</CBadge>
		case PAYMENT_STATUS_CANCELLED:
			return <CBadge color="warning">Dibatalkan</CBadge>
		default:
			return status
	}
}

export function transactionFrom(status) {
	switch (status) {
		case "bill":
			return <CBadge color="primary">SPP & Tagihan</CBadge>
		case "wallet":
			return <CBadge color="info">Tabungan</CBadge>
		case "allowance":
			return <CBadge color="info">Uang Saku</CBadge>
		default:
			return status
	}
}

export function parsialPayment(is_partial_payment) {
	if (is_partial_payment)
		return <CBadge color="primary">Pembayaran Parsial/Angsur</CBadge>
	return ""
}


export const getFeature = (ponpesId, featureTag) => {
	if (ponpesId === null) {
		return new Promise((resolve, reject) => {
			resolve("Async Success!");
		});
	}

	return HttpSend({
		method: "GET",
		to: API_V1 + "/features/get_feature_tag",
		params: {
			ponpes_id: ponpesId,
			feature_tag: featureTag
		},
	})
}

export const getAccountName = (account) => {
	return account.account_code ? account.account_code + ". " + account.account_name : account.account_name
}

export const getJenisBiayaStyle = (paymentType) => {
	switch (paymentType) {
		case "monthly":
			return <CBadge color="success">Bulanan</CBadge>
		case "non-monthly":
			return <CBadge color="primary">Non Bulanan</CBadge>
		case "donation":
			return <CBadge color="info">Infaq/Donasi</CBadge>
		default:
			return ""
	}
}

export const getJenisBiaya = (paymentType) => {
	switch (paymentType) {
		case "monthly":
			return "Bulanan"
		case "non-monthly":
			return "Non Bulanan"
		case "donation":
			return "Infaq/Donasi"
		default:
			return ""
	}
}

export const showTahunAkademik = (tahunAkademik) => {
	return tahunAkademik?.calendar_type === TAHUN_HIJRIYAH ? tahunAkademik?.tahun_hijriyah : tahunAkademik?.tahun_masehi
}

export const splitArray = (array, chunkSize) => {
	const result = [];

	for (let i = 0; i < array.length; i += chunkSize) {
		const chunk = array.slice(i, i + chunkSize);
		result.push(chunk);
	}

	return result;
}

export const getMessageType = (messageType) => {
	switch (messageType) {
		case MSG_TYPE_PROOF_OF_PAYMENT:
			return "Bukti Pembayaran"
		case MSG_TYPE_TOPUP_ALLOWANCE:
			return "Topup/Setor Uang Saku"
		case MSG_TYPE_WITHDRAW_ALLOWANCE:
			return "Withdraw/Penarikan Uang Saku"
		case MSG_TYPE_TOPUP_WALLET:
			return "Topup/Setor Tabungan"
		case MSG_TYPE_WITHDRAW_WALLET:
			return "Withdraw/Penarikan Tabungan"
		case MSG_TYPE_BROADCAST:
			return "Broadcast"
		default:
			break
	}
}

export const getMinMaxSemester = (tahunAjaranId) => {
	return HttpSend({
		to: API_V1 + "/semester/min_max_semester",
		method: 'GET',
		params: {
			tahun_ajaran_id: tahunAjaranId
		}
	})
}

export const getWaliSantri = (santriId) => {
	return HttpSend({
		to: API_V1 + "/santri/get_wali",
		method: 'GET',
		params: {
			santri_id: santriId
		}
	})
}

export const cleanPhoneNumber = (phoneNumber) => {
	let cleanNumber = phoneNumber.replace(/\+62/, "0").replace(/[\(\)\s\-]/g, "")

	if (cleanNumber.substring(0, 1) !== '0') {
		cleanNumber = "0" + cleanNumber
	}

	return cleanNumber
}

export const directPrint = (url) => {
	let iframe = document.createElement('iframe');
	document.body.appendChild(iframe);

	iframe.style.display = 'none';
	iframe.src = url
	iframe.onload = function () {
		setTimeout(function () {
			iframe.focus();
			iframe.contentWindow.print();
		}, 1);
	};
}

export const getImageCompressQuality = ({ size }) => {
	let quality = 0.5
	if (size > 5000000) {
		quality = 0.1
	} else if (size > 2000000 && size < 5000000) {
		quality = 0.2
	} else if (size > 1000000 && size < 2000000) {
		quality = 0.5
	} else if (size > 500000 && size < 1000000) {
		quality = 0.7
	}
	console.log(`Image Quality in size ${size} = ${quality}`)
	return quality
}

export const setManifest = ({ ponpes }) => {
	const manifest = document.querySelector('link[rel="manifest"]');
	if (manifest) {
		fetch(manifest.href)
			.then(response => response.json())
			.then(data => {
				data.name = ponpes.custom_app_name;
				data.short_name = ponpes.custom_app_short_name;
				let icons = [{
					src: process.env.REACT_APP_FILE_DOMAIN + "/" + ponpes.logo,
					sizes: "any",
					type: "image/png"
				}]
				data.icons = icons
				data.start_url = ponpes.custom_domain
				const updatedManifest = JSON.stringify(data, null, 2);
				const blob = new Blob([updatedManifest], { type: 'application/json' });
				const manifestUrl = window.URL.createObjectURL(blob);
				manifest.href = manifestUrl;
			});
	}
}

export const setSelectedPonpes = (data) => {
	localStorage.setItem('selected_ponpes', JSON.stringify(data))
}

export const getSelectedPonpes = () => {
	let item = localStorage.getItem('selected_ponpes')
	if (item && item !== "undefined") {
		return JSON.parse(item)
	}
	return null
}

export const capitalizeEachWord = (str) => {
	if (str) {
		return str.split(' ').map(word => {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}).join(' ');
	}
}

export const wsHost = () => {
	if (process.env.REACT_APP_BASE_URL_REST_TRUE !== "http://localhost:3030") {
		return window.location.origin
	}
	return process.env.REACT_APP_BASE_URL_REST_TRUE
}

export const translateErrorType = (errorType) => {
	switch (errorType) {
		case ERROR_LOG_IMPORT_TRANSACTION:
			return "Transaksi"
		case ERROR_LOG_IMPORT_TRANSACTION_OUTSTANDING:
			return "Transaksi Tagihan Santri"
		case ERROR_LOG_IMPORT_TRANSACTION_WALLET:
			return "Transaksi Tagihan Tabungan"
		case ERROR_LOG_IMPORT_TRANSACTION_ALLOWANCE:
			return "Transaksi Tagihan Uang Saku"
		case ERROR_LOG_IMPORT_TRANSACTION_OTHERS:
			return "Transaksi Lainnya"
		case ERROR_LOG_IMPORT_BILLS:
			return "Tagihan Santri"
	}
}

export const removeHtmlTags = (str) => {
	if (str)
		return str.replace(/<\/?[^>]+(>|$)/g, "")
	return ""
}

export const translateQuestionLevel = (errorType, withBadge = true) => {
	switch (errorType) {
		case "easy":
			if (withBadge)
				return <CBadge color="dark">Mudah</CBadge>
			return "Mudah"
		case "medium":
			if (withBadge)
				return <CBadge color="primary">Menengah</CBadge>
			return "Menengah"
		case "difficult":
			if (withBadge)
				return <CBadge color="orange">Sulit</CBadge>
			return "Sulit"
	}
}

export const translateCbtAnswer = (answer) => {
	if (answer) {
		switch (answer) {
			case "true":
				return "Benar"
			case "false":
				return "Salah"
			default:
				return removeHtmlTags(answer)
		}
	}
	return ""
}

export const truncateText = (text, maxLength = 50) => {
	if (!text)
		return ""

	if (text.length <= maxLength) {
		return text;
	}
	return text.substring(0, maxLength) + '...';
}

export const isMultiAnswer = (answer) => {
	const nonNullCount = _.filter(answer, item => item.answer_weight_percentage !== null && item.answer_weight_percentage !== "" && item.answer_weight_percentage != 0).length;
	console.log("Non Null ", nonNullCount)
	return nonNullCount >= 2;
}

export const numberToAlphabet = (num) => {
	if (num < 1 || num > 26) {
		return null; // Return null for out of range numbers
	}
	return String.fromCharCode(64 + num);
}

export const popupMessage = ({ title, message, icon = 'success' }) => {
	Swal.fire({
		title,
		html: `<span class='font-14'>${message}</span>`,
		icon
	})
}

export const statusCbt = (status) => {
	switch (status) {
		case CBT_STATUS_NOT_YET_TAKEN:
			return <CBadge color='orange' >Belum Mengerjakan</CBadge>
		case CBT_STATUS_CURRENTLY_TAKING_EXAM:
			return <CBadge color='info' >Sedang Mengerjakan</CBadge>
		case CBT_STATUS_ALREADY_TAKEN_EXAMP:
			return <CBadge color='primary' >Telah Mengerjakan</CBadge>
		case CBT_STATUS_PASSED:
			return <CBadge color='success' >Lulus</CBadge>
		case CBT_STATUS_FAILED:
			return <CBadge color='danger' >Gagal</CBadge>
	}
}

export const getSelectedInstitution = () => {
	let item = localStorage.getItem('selected_institution')
	if (item && item !== "undefined") {
		return JSON.parse(item)
	}
	return null
}

export const remainingDaysDonation = (endFundraising) => {
	if (endFundraising) {
		const startDate = moment();
		const endDate = moment(endFundraising, "YYYY-MM-DD");

		return endDate.diff(startDate, 'days') + " hari lagi"
	} else {
		return "Tanpa Batas Waktu"
	}
}

export const renderStatusPayment = (transaction) => {
	if (transaction?.manual_status !== null) {
		switch (transaction?.manual_status) {
			case PAYMENT_STATUS_MANUAL_PENDING:
				if (transaction?.bank_account_number === null) {
					return <span className='text-danger font-weight-300'>Belum ditransfer</span>
				} else
					return <span className='text-orange font-weight-300'>Sedang diverifikasi</span>
			case PAYMENT_STATUS_MANUAL_ON_PROGRESS:
				return <span className='text-yellow font-weight-300'>Sedang diverifikasi</span>
			case PAYMENT_STATUS_MANUAL_SUCCESS:
				return <span className='text-success font-weight-300'>Berhasil</span>
			case PAYMENT_STATUS_MANUAL_REJECTED:
				return <span className='text-danger font-weight-300'>Tidak valid</span>
		}
	}
}

export function timeAgo(inputDate) {
	const now = moment(); // Get the current time
	const pastDate = moment(inputDate); // Convert the input date into a moment object

	// Calculate the difference between now and the past date
	const duration = moment.duration(now.diff(pastDate));

	if (duration.asSeconds() < 60) {
		return `${Math.floor(duration.asSeconds())} detik yang lalu`;
	} else if (duration.asMinutes() < 60) {
		return `${Math.floor(duration.asMinutes())} menit yang lalu`;
	} else if (duration.asHours() < 24) {
		return `${Math.floor(duration.asHours())} jam yang lalu`;
	} else if (duration.asDays() < 30) {
		return `${Math.floor(duration.asDays())} hari yang lalu`;
	} else if (duration.asMonths() < 12) {
		return `${Math.floor(duration.asMonths())} bulan yang lalu`;
	} else {
		return `${Math.floor(duration.asYears())} tahun yang lalu`;
	}
}

export const renderStatusDonation = (status) => {
	switch (status) {
		case "in-progress":
			return <CBadge color='info'>Belum diverifikasi</CBadge>
		case "valid":
			return <CBadge color='success'>Valid</CBadge>
		case "invalid":
			return <CBadge color='danger'>Tidak valid</CBadge>
	}
	
}

export function getPonpesByAcronym(acronym) {
	return HttpSend({
		to: API_V1 + "/ponpes/get_ponpes_by_acronym" ,
		method: 'GET',
		params:{
			acronym:acronym
		}
	})
}

export const weekDaysIndo = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"]

export const LEVEL_CALON_SANTRI = 4
export const LEVEL_SANTRI = 5
export const LEVEL_COMMON_USER = 3

export const STATUS_AKTIF = "A"
export const STATUS_TIDAK_AKTIF = "TA"
export const STATUS_LULUS = "L"
export const STATUS_KELUAR = "K"

export const PREDIKAT_MAPEL_WAJIB = 'mapel_wajib'
export const PREDIKAT_ADAB_LAINNYA = 'lain'

export const STATUS_INV_UNPAID = "unpaid"
export const STATUS_INV_PAID = "paid"
export const STATUS_INV_PAID_PARTIAL = "paid_partial"
export const STATUS_INV_NOT_ACTIVE = "not_active"
export const STATUS_INV_VOID = "void"

export const PAYMENT_METHOD_MANUAL = "manual"
export const PAYMENT_METHOD_VA = "va"
export const PAYMENT_METHOD_EWALLET = "ewallet"
export const PAYMENT_METHOD_QR = "qr"
export const PAYMENT_METHOD_OTHERS = "others"

export const TINY_API_KEY = "y7rzlx7e7yvbw5ltgwrdeeqlt7sfgxhl4e6oe7l2wxntq7us"

export const FEE_TYPE_FLAT = "flat"
export const FEE_TYPE_PERCENTAGE = "percentage"

export const PAYMENT_STATUS_PENDING = "pending"
export const PAYMENT_STATUS_SUCCESS = "success"
export const PAYMENT_STATUS_EXPIRED = "expired"
export const PAYMENT_STATUS_CANCELLED = "cancelled"



// Manual Payment Status
export const PAYMENT_STATUS_MANUAL_PENDING = "pending"
export const PAYMENT_STATUS_MANUAL_SUCCESS = "success"
export const PAYMENT_STATUS_MANUAL_REJECTED = "rejected"
export const PAYMENT_STATUS_MANUAL_ON_PROGRESS = "on_progress"

export const CONFIG_TAG_PREFIX_ORDER_ID = "prefix_order_id"


export const CONFIG_TAG_SEMESTER_AKADEMIK_CASARU = "semester_akademik_casaru"

export const TARIF_FOR_SANTRI = "santri"
export const TARIF_FOR_CALON_SANTRI = "calon_santri"

export const ACCOUNT_TYPE_REVENUE = "revenue"
export const ACCOUNT_TYPE_EXPENSE = "expense"

export const TAHUN_HIJRIYAH = "Hijriyah"
export const TAHUN_MASEHI = "Masehi"

export const PAYMENT_TYPE_MONTHLY = "monthly"
export const PAYMENT_TYPE_NON_MONTHLY = "non-monthly"
export const PAYMENT_TYPE_DONATION = "donation"

export const MSG_TYPE_PROOF_OF_PAYMENT = "proof_of_payment"
export const MSG_TYPE_TOPUP_WALLET = "topup_wallet"
export const MSG_TYPE_WITHDRAW_WALLET = "withdraw_wallet"
export const MSG_TYPE_TOPUP_ALLOWANCE = "topup_allowance"
export const MSG_TYPE_WITHDRAW_ALLOWANCE = "withdraw_allowance"
export const MSG_TYPE_BROADCAST = "broadcast"


export const ERROR_LOG_IMPORT_TRANSACTION = "transaction"
export const ERROR_LOG_IMPORT_TRANSACTION_OUTSTANDING = "transaction_outstanding"
export const ERROR_LOG_IMPORT_TRANSACTION_WALLET = "transaction_wallet"
export const ERROR_LOG_IMPORT_TRANSACTION_ALLOWANCE = "transaction_allowance"
export const ERROR_LOG_IMPORT_TRANSACTION_OTHERS = "transaction_others"
export const ERROR_LOG_IMPORT_BILLS = "bills"

export const CBT_QUESTION_TYPE_MULTIPLE_CHOICE = 1
export const CBT_QUESTION_TYPE_TRUE_FALSE = 2
export const CBT_QUESTION_TYPE_NUMERIC = 3
export const CBT_QUESTION_TYPE_ESSAY = 4

export const CBT_STATUS_NOT_YET_TAKEN = "not_yet_taken_exam"
export const CBT_STATUS_CURRENTLY_TAKING_EXAM = "currently_taking_exam"
export const CBT_STATUS_ALREADY_TAKEN_EXAMP = "already_taken_exam"
export const CBT_STATUS_PASSED = "passed"
export const CBT_STATUS_FAILED = "failed"
export const CBT_STATUS_NOT_ATTENDED_EXAM = "not_attended_exam"


export const CBT_SCORE_STATUS_NOT_CALCULATED = "not_yet_calculated"
export const CBT_SCORE_STATUS_IN_PROGRESS = "in_progress"
export const CBT_SCORE_STATUS_COMPLETED = "completed"
export const CBT_SCORE_STATUS_FAILED = "failed"

export const POST_TYPE_ARTICLE = "article"
export const POST_TYPE_ANNOUNCEMENT = "announcement"
export const POST_TYPE_PSB = "psb"