import React, { useEffect, useState } from 'react'
import {
	CAlert,
	CButton,
	CCard,
	CCardBody,
	CCardGroup,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormLabel,
	CInputGroup,
	CInputGroupText,
	CRow,
} from '@coreui/react'
import { HttpSend } from 'src/util/Network'
import { useDispatch } from 'react-redux'
import BlockUI from 'src/components/BlockUI'
import { getBaseUrl, isMobile, valueInput } from 'src/util/Util'
import CIconFa from 'src/components/CIconFa'
import BackTo from 'src/components/BackTo'
import { API_V1 } from 'src/util/api'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'

const ResetPassword = () => {
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errorList, setErrorList] = useState([])

	const [generalError, setGeneralError] = useState('')
	const dispatch = useDispatch()
	const {reset_password_link} = useParams()

	useEffect(()=>{
		if(reset_password_link){
			if(isMobile()){
				// window.location.href = "sipa://request_reset_password/"+reset_password_link
			}
			checkResetPasswordLink()
		}
	}, [reset_password_link])

	const doReset = (e) => {
		e.preventDefault()
		setGeneralError('')
		setErrorList([])
		dispatch({ type: "set", isLoading: true })
		HttpSend({
			to: API_V1 + '/auth/reset_new_password',
			formData: {
				new_password: newPassword,
				confirm_password : confirmPassword,
				reset_password_link:reset_password_link,
			},
			method: 'PUT'
		}).then(result => {
			dispatch({ type: "set", isLoading: false })
			if (result.status === 200) {
				Swal.fire({
					title:'Sukses',
					text:'Reset password berhasil dilakukan',
					icon:'success',
					allowOutsideClick:false
				}).then(r=>{
					window.location.href = getBaseUrl()
				})
			} else if (result.status === 10){
				setErrorList(result.errors)
			}else {
				setGeneralError(result.message)
			}
		})
	}

	const onChange = (e) => {
		const target = e.target
		const value = valueInput(e)
		switch (target.name) {
			case 'newPassword':
				setNewPassword(value)
				break
			case 'confirmPassword':
				setConfirmPassword(value)
				break
		}
	}

	const errorValidationMessage = (field) => {
		const index = errorList.findIndex(item => item.context.key === field);
		return errorList[index] ? errorList[index].message : null
	}

	const checkResetPasswordLink=()=>{
		HttpSend({
			to:API_V1+'/auth/check_reset_password_link',
			method:'GET',
			params:{
				reset_password_link:reset_password_link
			}
		}).then(result=>{
			if(result.status === 200){

			} else{
				Swal.fire({
					title:'Terjadi Kesalahan',
					text:result.message,
					icon:'error',
					allowOutsideClick:false
				}).then(r=>{

				})
			}
		})
	}

	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={5}>
						<CCardGroup>
							<CCard className="p-4">
								<CCardBody>
									<BackTo to={"/login"} />
									<CForm onSubmit={doReset}>
										<h2>Reset Password</h2>
										<hr />
										<p className="text-medium-emphasis">Masukkan Password Baru</p>
										<CRow className="mb-3">
											<CCol md="4">
												<CFormLabel>Password Baru</CFormLabel>
											</CCol>
											<CCol xs="12" md="8">
												<CFormInput type="password" onChange={onChange} name="newPassword" value={newPassword} feedbackInvalid={errorValidationMessage("new_password", errorList)} invalid={errorValidationMessage("new_password", errorList) !== null} />
											</CCol>
										</CRow>

										<CRow className="mb-3">
											<CCol md="4">
												<CFormLabel>Ulangi Password</CFormLabel>
											</CCol>
											<CCol xs="12" md="8">
												<CFormInput type="password" onChange={onChange} name="confirmPassword" value={confirmPassword} feedbackInvalid={errorValidationMessage("confirm_password", errorList)} invalid={errorValidationMessage("confirm_password", errorList) !== null} />
											</CCol>
										</CRow>
										{generalError ? <CAlert color='danger'>{generalError}</CAlert> : ""}
										<CRow>
											<CButton color="primary" type='submit' className="mt-3" active tabIndex={-1}>
												Ubah Password
											</CButton>
										</CRow>
									</CForm>
								</CCardBody>
							</CCard>
						</CCardGroup>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	)
}

export default ResetPassword
